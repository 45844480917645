import { createTheme } from "@mui/material/styles";
import palette from "./palette";

const spacing = [
  0, 2, 4, 8, 12, 16, 20, 24, 32, 36, 48, 64, 80, 100, 120, 140, 160,
];

const breakpoints = {
  values: {
    xs: 390,
    sm: 540,
    md: 768,
    lg: 1200,
    xl: 1440,
  },
};

const theme = createTheme({
  spacing: spacing,
  breakpoints: breakpoints,
  palette: palette,
});

export default theme;
