import login from "./login";
import resetPassword from "./resetPassword";
import forgotPassword from "./forgotPassword";
import Translation from "./Translation";

const TRANSLATIONS_JA = {
  login,
  resetPassword,
  forgotPassword,
  Translation,
};
export default TRANSLATIONS_JA;
