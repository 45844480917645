const Translation = {
  "Access Point": "搬出入ゲート",
  accessPoints: "搬出入ゲート",
  "Vehicle type": "車両タイプ",
  vehicleTypes: "車両タイプ",
  booking_date: "予定日",
  booking_ref: "予約ID",
  error_something_went_wrong: "何かがうまくいかなかった...",
  filters: "フィルター",
  filter: "フィルター",
  Status: "スターテス",
  statuses: "スターテス",
  Projects: "プロジェクト",
  Organization: "組織",
  organizations: "組織",
  Organizations: "組織",
  Tracking: "追跡",
  "Vehicle Tracking": "車両追跡",
  "Enable access to the vehicle tracking and mobile routing feature":
    "車両追跡およびモバイル ルーティング機能へのアクセスを有効にする",
  reset_filter: "リセットフィルター",
  apply_filter: "フィルターを適用します",
  users: "ユーザー",
  total_user: "総ユーザー",
  total_Organization: "総組織",
  active_users: "アクティブユーザー",
  clear_all: "すべてクリア",
  showing: "表示",
  no_sdata_found: "何もデータが見つかりませんでした",
  enteris_off: "のエントリ",
  entries: "エントリ",
  search_by_User_parameter_in_userlist:
    "ユーザー名、組織名、メールアドレスで検索",
  search_by_project_name: "プロジェクト名で検索します",
  organization_Name: "組織名",
  organization_Admin: "組織管理者",
  Country: "国",
  associated_Projects: "関連プロジェクト",
  others: "その他",
  search_by_organization_name: "組織名で検索します",
  close: "近い",
  tap_to_edit: "タップしてプロファイル画像を編集します",
  first_name_required: "名が必要です",
  last_name_required: "姓が必要です",
  email_name_required: "メールが必要です",
  phone_name_required: "電話が必要です",
  email: "Eメール",
  phone: "電話",
  save_details: "詳細を保存します",
  organization_role: "組織の役割",
  user_status: "ユーザーステータス",
  global_user_status: "グローバル管理ステータス",
  personal_details: "個人的な詳細",
  organization_created: "正常に作成された組織",
  select_country: "国を選択",
  org_name_required: "組織名が必要です",
  address_required: "アドレス名が必要です",
  city_required: "町/都市が必要です",
  saved_successfully: "詳細を正常に保存します",
  organization_details: "組織の詳細",
  address_line_1_required: "address1が必要です",
  address_line_2_required: "address2が必要です",
  zip_required: "zipが必要です",
  country_required: "国が必要です",
  organization_logo: "組織ロゴ",
  statement_info_text:
    "ステートメントテキストにリンクを含めるには、次の形式を使用します{link text、https://link.com}。",
  question_required: "質問が必要です",
  create_project: "プロジェクトを作成します",
  user_data_saved_successfully: "ユーザー情報を正常に更新しました",
  section_visibility: "セクションの可視性",
  project_setting: "プロジェクト設定",
  project_settings_info:
    "プロジェクト設定は、「Pro」機能と統合にアクセスして構成できる場所です。これを有効にすると、プロジェクト管理者がプロジェクト設定領域が表示され、アクセス可能になります。",
  integration: "統合",
  integration_info:
    "統合は、サードパーティの統合を有効にして構成できる場所です。これを有効にすると、統合領域が目に見えるようになり、プロジェクト管理者がアクセスできます。統合はプロジェクト設定に含まれており、両方とも統合領域にアクセスするためにアクティブでなければなりません。",
  enable_pro_settings: "プロの設定を有効にします",
  procore_daily_log: "Procore Daily Log",
  procore_daily_log_info:
    "Procore Daily Logを構成するためにアクセスを有効にします。この機能は、Procoreの統合に依存しています。",
  enable_integrations: "統合を有効にします",
  fors_validation: "FORS検証",
  fors_validation_info: "FORS検証の構成にアクセスを有効にします。",
  procore: "プロコール",
  enable_access_config: "Procore統合の構成にアクセスできるようにします。",
  feature_updated: "正常に更新された機能",
  integrations_updated: "統合を正常に更新しました",
  verification: "検証",
  organization_state: "組織状態",
  select_org_state: "組織状態を選択してください",
  verified_email_domain: "検証済みの電子メールドメイン",
  email_domain_required: "検証済みの電子メールドメインを選択してください",
  verified: "検証",
  unverified: "未検証",
  scheduling: "スケジューリング",
  field: "分野",
  edit: "編集",
  duration: "間隔",
  core_fields: "コアフィールド",
  active: "アクティブ",
  Active: "アクティブ",
  mandatory: "必須",
  flexible_fields: "柔軟なフィールド",
  add_new_field: "新しいフィールドを追加します",
  core_field_saved_success_fully: "コアフィールドの詳細を正常に保存します",
  error_login_again: "認証資格情報は提供されていません",
  vehicle_types: "車両タイプ",
  included: "含まれています",
  cancel: "キャンセル",
  save: "保存",
  change_associated_organization: "関連する組織を変更します",
  confirm: "確認",
  new_organization: "新しい組織",
  changed_organization: "組織の変更に成功します",
  no_data_found: "何もデータが見つかりませんでした",
  collapse: "崩壊",
  new_project: "新しいプロジェクト",
  field_removed: "フィールドが削除されました",
  this_field_is_active: "このフィールドはアクティブです",
  this_field_is_mandatory: "このフィールドは必須です",
  core_field_saved: "コアフィールドの詳細を正常に保存します",
  search: "検索",
  from: "から",
  to: "に",
  today: "今日",
  yesterday: "昨日",
  start_date_greater_than_end_date: "開始日は終了日より大きくはありません",
  back: "戻る",
  account_created: "アカウントが作成されました",
  last_login: "前回のログイン",
  link: "リンク",
  status: "スターテス",
  First_Name: "ファーストネーム",
  Last_Name: "苗字",
  Email_Address: "電子メールアドレス",
  Associated_Organization: "関連する組織",
  Associated_Projects: "関連プロジェクト",
  account_manager: "アカウントマネージャー",
  organization: "組織",
  apply_filters: "フィルターを適用します",
  next: "次",
  previous: "前",
  no_activity_found: "アクティビティは見つかりません",
  create_organization: "新しい組織を追加します",
  forgot_password: "パスワードをお忘れですか",
  welcometext_forgot:
    "メールを入力してください。パスワードをリセットする手順をお送りします。",
  backtologin: "ログインに戻る",
  submit: "送信",
  error_email_required: "メールが必要です",
  error_valid_email: "有効なメールアドレスを入力してください",
  welcometext: "Voyage Controlへようこそ",
  pls_login: "ログインしてください",
  password: "パスワード",
  login: "ログイン",
  booking_type: "予約タイプ",
  site_defaults: "サイトのデフォルト",
  project: "プロジェクト",
  total_project: "総プロジェクト",
  project_status: "プロジェクトステータス",
  active_project: "アクティブなプロジェクト",
  site_settings: "サイト設定",
  automatic_approval: "自動承認",
  automatic_approval_description:
    "すべての予約は、作成後に自動的に承認されます。",
  unauthorize_user: "アクセス拒否",
  edit_project_details: "プロジェクトの詳細を編集します",
  credential_not_provided: "認証資格情報は提供されていません",
  region_not_available: "現在の地域は利用できません",
  btn_back: "戻る",
  date_created: "作成された日付",
  local_site_time: "現場での現地時間",
  successfully_edit_contact: "連絡先を正常に編集します",
  invalid_url: "有効なURLを入力してください",
  project_owner_email_address: "プロジェクト所有者のメールアドレス",
  project_edited: "プロジェクトの詳細は編集されました",
  please_contact_selected_owner_to_accept_the_invitation:
    "招待状を受け入れるには、選択した所有者に連絡してください",
  please_select_owner: "所有者を選択してください",
  success_fully_added_contact: "連絡先を正常に追加します",
  image_uploaded: "正常にアップロードされた画像",
  contact_deleted: "削除された連絡先",
  project_organization: "プロジェクト組織",
  select_site: "サイトを選択します",
  any_integration_lost: "統合構成が失われる場合",
  disable: "無効にする",
  go: "行く",
  actions: "行動",
  go_to_project: "プロジェクトに移動します",
  project_settings_updated: "プロジェクト設定が更新されました",
  invalid_date: "無効な日付",
  added_flexible_field_data: "柔軟なフィールドデータを追加します",
  title_is_required: "タイトルが必要です",
  question_is_required: "質問が必要です",
  option_is_required: "オプションが必要です",
  title: "タイトル*",
  question: "質問*",
  statement: "声明*",
  field_type: "フィールドタイプ*",
  options: "オプション",
  add: "追加",
  add_new_option: "新しいオプションを追加します",
  edit_field: "フィールドを編集します",
  add_field: "フィールドを追加します",
  free_text: "フリーテキスト",
  confirm_text: "これを永久に削除することを確認してください",
  can_not_undo: "このアクションを元に戻すことはできません",
  delete: "消去",
  select_role: "役割を選択します",
  user_removed: "ユーザーは組織から削除されます",
  organization_updated: "組織の役割が更新されました",
  no_user: "ユーザーは見つかりませんでした",
  flex_fields_added: "正常に柔軟なフィールドを追加します",
  edit_flex_field: "柔軟なフィールドを編集します",
  add_flex_field: "新しい柔軟なフィールドを追加します",
  org_name: "組織名",
  address_line_1: "住所1",
  address_line_2: "住所2",
  town_city: "町/都市",
  town_city_required: "町/都市が必要です",
  zip_code: "郵便番号",
  zip_code_required: "zipcodeが必要です",
  country: "国",
  reset_password: "パスワードを再設定する",
  welcometext_reset: "パスワードには少なくとも6文字が必要です",
  confirmpassword: "パスワードを認証する",
  error_password_required: "パスワードが必要",
  error_confirm_password_required: "パスワードを確認する必要があります",
  error_password_upper: "少なくとも1つの大文字",
  error_password_lower: "少なくとも1つの小文字",
  error_password_number: "少なくとも1桁",
  error_password_special: "少なくとも1つの特別なキャラクター",
  error_password_min: "最低6文字",
  error_password_max: "最大16文字",
  error_valid_password:
    "少なくとも1つの大文字、少なくとも1つの小文字、少なくとも1桁、少なくとも1つの特殊文字、最低6文字、最大16文字。",
  error_password_not_match: "パスワードが一致しません",
  success_message: "パスワードリセットを正常にリセットします",
  error_language_required: "言語が必要です",
  error_dateformat_required: "日付形式が必要です",
  error_timeformat_invalid: "TimeFormatが必要です",
  error_startday_required: "初日が必要です",
  error_status_required: "ステータスが必要です",
  details_heading_text: "プロジェクトの詳細",
  project_name: "プロジェクト名",
  project_lead: "プロジェクトリード",
  project_owner: "プロジェクトオーナー",
  project_org: "プロジェクト組織",
  project_add: "住所",
  edit_project_detail: "プロジェクトの詳細を編集します",
  heading_contact: "連絡先の詳細",
  logo: "ロゴ",
  home_page_banner: "ホームバナー",
  details_image_text: "画像とホーム画面",
  label_sitename: "プロジェクト名",
  label_siteadd: "プロジェクトアドレス",
  label_siteOwner: "プロジェクトオーナー",
  label_siteOrg: "プロジェクト組織",
  label_accManager: "アカウントマネージャー",
  label_hubspot: "hubspotリンク",
  label_home_banner: "ホームバナー",
  label_region: "領域",
  error_region_required: "地域が必要です",
  label_home_screen_message: "ホーム画面メッセージ",
  error_image_invalid: "有効な画像ファイルをアップロードしてください",
  error_siteLogo_required: "サイトのロゴが必要です",
  error_homebanner_required: "バナー画像が必要です",
  error_sitename_required: "プロジェクト名が必要です",
  error_siteadd_required: "プロジェクトアドレスが必要です",
  error_siteowner_required: "プロジェクト所有者が必要です",
  error_siteorg_required: "プロジェクト組織が必要です",
  error_acc_manager_required: "アカウントマネージャーが必要です",
  error_hubspot_url: "HubSpot URLが必要です",
  error_homemessage_required: "ホーム画面メッセージが必要です",
  contact_heading_text: "連絡先",
  add_contact: "新しい連絡先を追加します",
  edit_contact: "連絡先を編集します",
  label_name: "名前",
  label_position: "位置",
  label_phone: "電話番号（オプション）",
  btn_add: "追加",
  btn_remove: "削除",
  btn_edit: "編集",
  btn_save: "保存",
  error_name_required: "名前が必要です",
  error_position_required: "位置が必要です",
  error_valid_phonenumber: "有効な電話番号を入力してください",
  error_required_phone: "電話が必要です",
  drag_and_drop: "ファイルをドラッグアンドドロップします",
  browse_file: "ブラウズファイル",
  image_uploading: "画像のアップロード",
  select: "選択する",
  address: "住所",
  hubspot: "hubspot",
  "pre-live": "ライブ",
  paused: "一時停止",
  finished: "終了した",
  invited: "招待",
  Invited: "招待",
  restricted: "制限付き",
  blocked: "ブロックされています",
  logout: "ログアウト",
  email_optional_label: "メールアドレス（オプション）",
  project_information: "プロジェクト情報",
  project_settings: "プロジェクト設定",
  booking_fields: "予約フィールド",
  project_users: "プロジェクトユーザー",
  project_activity: "プロジェクトアクティビティ",
  organization_info: "組織情報",
  organization_users: "組織ユーザー",
  organization_activity: "組織活動",
  user_information: "ユーザー情報",
  associated_organizations: "関連組織",
  associated_projects: "関連プロジェクト",
  user_activity: "ユーザーアクティビティ",
  vehicles_manager: "車両マネージャー",
  vehicle_type_added: "車種を追加しました",
  vehicle_type_updated: "車両タイプを更新しました",
  vehicle_type_deleted: "削除された車種",
  no_vehicles_found: "車種が見つかりません",
  add_vehicle: "新しい車両タイプを追加",
  multi_vehicle_description:
    "マルチチェキンの予約を使用すると、それぞれの個別の予約をするのではなく、現場に到着する車両の数を定義できます。",
  recurring_description:
    "予約をスケジュールして、毎週または月に毎日繰り返すことができます。たとえば、毎週のゴミコレクション。",
  resource_only_description:
    "アクセスポイントで予約スペースをとることなく、利用可能なリソースのみを予約できます。",
  multi_vehicle: "マルチ車",
  recurring: "繰り返し",
  resource_only: "リソースのみ",
  Resource: "リソース",
  invite_user: "ユーザーを招待します",
  add_another: "別のものを追加します",
  role: "役割",
  project_admin: "プロジェクト管理者",
  project_manager: "プロジェクトマネージャ",
  project_user: "プロジェクトユーザー",
  send_invite: "招待を送る",
  invite_sent: "招待状に正常に送信されました",
  security_guard: "警備員",
  view_only: "表示のみ",
  project_senior_user: "プロジェクトシニアユーザー",
  profile: "プロフィール",
  language: "言語",
  "Booking Contact": "予約の連絡先",
  Comments: "コメント",
  "Vehicle Type": "車両タイプ",
  phone_number: "電話番号",
  project_role: "プロジェクトの役割",
  Van: "バン",
  "18 Wheeler": "18ウィーラー",
  "Box Truck": "ボックストラック",
  "Car + Trailer": "車 +トレーラー",
  Car: "車",
  "Concrete Truck": "コンクリートトラック",
  "Dump Truck": "ダンプトラック",
  "Flatbed Truck": "フラットベッドトラック",
  "Garbage Truck": "ごみ収集車",
  "Mobile Crane": "モバイルクレーン",
  Other: "他の",
  "Pickup Truck": "ピックアップトラック",
  "Pump Truck": "ポンプトラック",
  Tanker: "タンカー",
  "Articulated Vehicle": "明確な車両",
  "Raised Floor Trailer": "高床トレーラー",
  "Low Floor Trailer": "低床トレーラー",
  "15t Flat Truck": "15t平車",
  "15t Unic Truck": "15tユニック車",
  "10t Flat Truck": "10t平車",
  "10t Unic Truck": "10tユニック車",
  "8t Flat Truck": "8t平車",
  "8t Unic Truck": "8tユニック車",
  "4t Flat Truck": "4t平車",
  "4t Unic Truck": "4tユニック車",
  "2t Flat Truck": "2t平車",
  "Concrete Mixer Truck": "生コン車",
  "16t Rough Terrain-Crane": "16tラフテレーンクレーン",
  "25t Rough Terrain-Crane": "25tラフテレーンクレーン",
  "50t Rough Terrain-Crane": "50tラフテレーンクレーン",
  "65t Rough Terrain-Crane": "65tラフテレーンクレーン",
  "10t dump truck": "ダンプカー10t",
  "10t Dump Truck": "ダンプカー10t",
  "10t arm roll": "アームロール10t",
  "10t Arm Roll": "アームロール10t",
  "4t arm roll": "アームロール4t",
  "4t Arm Roll": "アームロール4t",

  flexible_forms: "柔軟なフォーム",
  add_preset_form: "プリセットフォームの追加",
  create_preset_form: "新しいフォームの作成",
  order: "注文",
  form: "形状",
  forms: "フォーム",
  preset_form: "プリセットフォーム",
  add_question: "質問を追加",
  add_section: "セクションの追加",
  form_title: "フォームのタイトル",
  form_title_is_required: "フォームのタイトルは必須です",
  section_title: "セクションのタイトル",
  section_title_is_required: "セクションのタイトルは必須です",
  new_question: "新しい質問",
  no_flex_form: "柔軟なフォームが設定されていません",
  check_in_form: "チェックインフォーム",
  check_out_form: "チェックアウトフォーム",
  form_updated_successfully: "フォームが正常に更新されました",
  form_created_successfully: "フォームが正常に作成されました",
  label: "ラベル",
  question_title: "質問",
  form_deleted_successfully: "フォームが正常に削除されました",
  create_new_form: "新しいフォームの作成",
  please_fill_the_required_fields: "いくつかの必須フィールドが欠落しています",
  save_to_preset_form: "プリセットフォームに保存",
  save_form: "フォームを保存",
  question_is_mandatory: "質問は必須です",
  go_to_section_base_on_answer: "回答に基づいてセクションに移動します",
  continue_to: "続けて",
  delete_preset_form: "プリセットフォームの削除",
  edit_preset_form: "プリセットフォームの編集",
};
export default Translation;
