const Translation = {
  "Access Point": "Access Point",
  accessPoints: "Access Points",
  "Vehicle type": "Vehicle Type",
  vehicleTypes: "Vehicle Types",
  booking_date: "Booking Date",
  booking_ref: "Booking Ref",
  error_something_went_wrong: "Something Went Wrong...",
  filters: "Filters",
  filter: "Filter",
  Status: "Status",
  statuses: "Statuses",
  Projects: "Projects",
  Organization: "Organization",
  organizations: "Organizations",
  Organizations: "Organizations",
  Tracking: "Tracking",
  "Vehicle Tracking": "Vehicle Tracking",
  "Enable access to the vehicle tracking and mobile routing feature":
    "Enable access to the vehicle tracking and mobile routing feature",
  reset_filter: "Reset Filter",
  apply_filter: "Apply Filters",
  users: "Users",
  total_user: "Total Users",
  total_Organization: "Total Organizations",
  active_users: "Active Users",
  clear_all: "Clear All",
  showing: "Showing",
  no_sdata_found: "No Data Found",
  enteris_off: "entries of",
  entries: "entries",
  search_by_User_parameter_in_userlist:
    "Search by User Name, Organization Name, Email Address",
  search_by_project_name: "Search by Project Name",
  organization_Name: "Organization Name",
  organization_Admin: "Organization Admin",
  Country: "Country",
  associated_Projects: "Associated Projects",
  others: "others",
  search_by_organization_name: "Search by Organization Name",
  close: "Close",
  tap_to_edit: "Tap to edit profile picture",
  first_name_required: "First Name is required",
  last_name_required: "Last Name is required",
  email_name_required: "Email is required",
  phone_name_required: "Phone is required",
  email: "Email",
  phone: "Phone",
  save_details: "Save details",
  organization_role: "Organization Role",
  user_status: "User Status",
  global_user_status: "Global Admin Status",
  personal_details: "Personal Details",
  organization_created: "Successfully Created Organization",
  select_country: "Select Country",
  org_name_required: "Organization Name is required",
  address_required: "Address Name is required",
  city_required: "Town/City is required",
  saved_successfully: "Save Detail Successfully",
  organization_details: "Organization Details",
  address_line_1_required: "Address1 is Required",
  address_line_2_required: "Address2 is Required",
  zip_required: "ZIP is required",
  country_required: "Country is required",
  organization_logo: "Organization Logo",
  statement_info_text:
    "To include a link in the statement text use the following format {link text, https://link.com}.",
  question_required: "Question is required",
  create_project: "Create Project",
  user_data_saved_successfully: "Successfully updated user information ",
  section_visibility: "Section Visibility",
  project_setting: "Project Settings",
  project_settings_info:
    "Project settings is where ‘Pro’ features and integrations can be accessed and configured. Enabling this will make the Project Settings area visible and accessible to project admins.",
  integration: "Integrations ",
  integration_info:
    "Integrations is where 3rd party integrations can be enabled and configured. Enabling this will make the Integrations area visible and accessible to project admins. Integrations is contained within Project Settings, both must be active to access the Integrations area.",
  enable_pro_settings: "Enable Pro Settings",
  procore_daily_log: "Procore Daily Log",
  procore_daily_log_info:
    "Enable access to configure procore daily log. This feature relies on the procore integration. ",
  enable_integrations: "Enable Integrations",
  fors_validation: "FORS Validation",
  fors_validation_info: "Enable access to configure FORS validation.",
  procore: "Procore",
  enable_access_config: "Enable access to configure procore integration.",
  feature_updated: "Successfully Updated Feature",
  integrations_updated: "Successfully Updated Integrations",
  verification: "Verification",
  organization_state: "Organization State",
  select_org_state: "Please Select Organization State",
  verified_email_domain: "Verified Email Domains",
  email_domain_required: "Please Select Verified Email Domains",
  verified: "Verified",
  unverified: "UnVerified",
  scheduling: "Scheduling",
  field: "Field",
  edit: "Edit",
  duration: "Duration",
  core_fields: "Core Fields",
  active: "Active",
  summary: "Show in Summary",
  Active: "Active",
  mandatory: "Mandatory",
  flexible_fields: "Flexible Fields",
  add_new_field: "Add new Field",
  core_field_saved_success_fully: "Save Successfully CoreField Detail",
  error_login_again: "Authentication Credentials not provided",
  vehicle_types: "Vehicle Types",
  included: "Included",
  cancel: "Cancel",
  save: "Save",
  change_associated_organization: "Change Associated Organization",
  confirm: "Confirm",
  new_organization: "New Organization",
  changed_organization: "Organization Changes SuccessFully",
  no_data_found: "No Data found",
  collapse: "Collapse",
  new_project: "New Project",
  field_removed: "Field Removed",
  this_field_is_active: "This Field is Active",
  this_field_is_mandatory: "This Field is Mandatory",
  core_field_saved: "Save Successfully CoreField Detail",
  search: "Search",
  from: "From",
  to: "To",
  today: "TODAY",
  yesterday: "YESTERDAY",
  start_date_greater_than_end_date:
    "Start Date Should not be greater than End Date",
  back: "Back",
  account_created: "Account Created",
  last_login: "Last Login",
  link: "Link",
  status: "Status",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Email_Address: "Email Address",
  Associated_Organization: "Associated Organization",
  Associated_Projects: "Associated Projects",
  account_manager: "Account Manager",
  organization: "Organization",
  apply_filters: "Apply Filters",
  next: "Next",
  previous: "Previous",
  no_activity_found: "No Activity Found",
  create_organization: "Add New Organization",
  forgot_password: "Forgot Password",
  welcometext_forgot:
    "Please enter your email and we will send you instructions to reset your password.",
  backtologin: "Back to login",
  submit: "Submit",
  error_email_required: "Email is required",
  error_valid_email: "Please enter valid email address",
  welcometext: "Welcome To Voyage Control",
  pls_login: "Please Log In",
  password: "Password",
  login: "Login",
  booking_type: "Booking Type",
  site_defaults: "Site Defaults",
  project: "Projects",
  total_project: "Total Projects",
  project_status: "Project Status",
  active_project: "Active Projects",
  site_settings: "Site Settings",
  automatic_approval: "Automatic Approval",
  automatic_approval_description:
    "All Booking will automatically approved after they are made.",
  unauthorize_user: "Access Denied",
  edit_project_details: "Edit Project Detail",
  credential_not_provided: "Authentication Credentials Not Provided",
  region_not_available: "Current region not available",
  btn_back: "Back",
  date_created: "Date Created",
  local_site_time: "Local Time at Site",
  successfully_edit_contact: "Successfully Edit Contact",
  invalid_url: "Please enter valid URL",
  project_owner_email_address: "Project Owner Email Address",
  project_edited: "Project Details Edited SuccessFully",
  please_contact_selected_owner_to_accept_the_invitation:
    "Please Contact selected Owner To Accept the Invitation",
  please_select_owner: "Please select Owner",
  success_fully_added_contact: "Successfully Add Contact",
  image_uploaded: "Successfully uploaded Image",
  contact_deleted: "Successfully deleted Contact",
  project_organization: "Project Organization",
  select_site: "Select Site",
  any_integration_lost: "Any integration configurations with be lost",
  disable: "Disable",
  go: "GO",
  actions: "Actions",
  go_to_project: "Go to project",
  project_settings_updated: "Project Setting Updated",
  invalid_date: "Invalid Date",
  added_flexible_field_data: "Add Flexible Field Data",
  title_is_required: "Title is required",
  question_is_required: "Question is required",
  option_is_required: "Option is required",
  title: "Title*",
  question: "Question*",
  statement: "Statement*",
  field_type: "Field Type*",
  options: "Options*",
  add: "Add",
  add_new_option: "Add New Option",
  edit_field: "Edit Field",
  add_field: "Add Field",
  free_text: "Free Text",
  confirm_text: "Are you sure you want to permanently delete this",
  can_not_undo: "You can’t undo this action",
  delete: "Delete",
  select_role: "Select Role",
  user_removed: "User is removed From Organization",
  organization_updated: "Organization Role updated",
  no_user: "No User found ",
  flex_fields_added: "Add Successfully Flexible Field",
  edit_flex_field: "Edit Flexible Field",
  add_flex_field: "Add New Flexible Field",
  org_name: "Organization Name",
  address_line_1: "Address Line 1",
  address_line_2: "Address Line 2",
  town_city: "Town/City",
  town_city_required: "Town/City is Required",
  zip_code: "Zipcode",
  zip_code_required: "Zipcode is Required",
  country: "Country",
  reset_password: "Reset Password",
  welcometext_reset: "The password shoud have atleast 6 characters",
  confirmpassword: "Confirm Password",
  forms: "Forms",
  save_to_preset_form: "Save to preset forms",
  delete_preset_form: "Delete Preset Form",
  edit_preset_form: "Edit Preset Form",
  section: "Section",
  create_new_form: "Create New Form",
  some_required_field_is_missing: "Missing some required fields",
  added_flexible_form: "Successfully added new flexible form",
  save_form: "Save Form",
  question_is_mandatory: "Question is mandatory",
  please_add_one_option: "Please add at least one option",
  please_add_value: "Please enter a value",
  please_fill_the_required_fields: "Please fill the all required fields",
  error_password_required: "Password is required",
  error_confirm_password_required: "Confirm Password is required",
  error_password_upper: "At least 1 uppercase character",
  error_password_lower: "At least 1 lowercase character",
  error_password_number: "At least 1 digit",
  error_password_special: "At least 1 special character",
  error_password_min: "Minimum 6 characters",
  error_password_max: "Maximum 16 characters",
  error_valid_password:
    "At least 1 uppercase character,At least 1 lowercase character, At least 1 digit, At least 1 special character,Minimum 6 characters and Maximum 16 characters.",
  error_password_not_match: "The passwords do not match",
  success_message: "Password Reset Successfully",
  error_language_required: "Language is required",
  error_dateformat_required: "Date Formate is required",
  error_timeformat_invalid: "Timeformat is required",
  error_startday_required: "StartDay is required",
  error_status_required: "Status is required",
  details_heading_text: "Project Details",
  project_name: "Project Name",
  project_lead: "Project Lead",
  project_owner: "Project Owner",
  project_org: "Project Organization",
  project_add: "Address",
  edit_project_detail: "Edit project details",
  heading_contact: "Contact Details",
  logo: "Logo",
  home_page_banner: "Home Banner",
  details_image_text: "Images & Home Screen",
  label_sitename: "Project Name",
  label_siteadd: "Project Address",
  label_siteOwner: "Project Owner",
  label_siteOrg: "Project Organization",
  label_accManager: "Account Manager",
  label_hubspot: "Hubspot Link",
  label_home_banner: "Home Banner",
  label_region: "Region",
  error_region_required: "Region is required",
  label_home_screen_message: "Home Screen Message",
  error_image_invalid: "Please upload valid image file",
  error_siteLogo_required: "Site Logo is required",
  error_homebanner_required: "Banner Image is required",
  error_sitename_required: "Project Name is required",
  error_siteadd_required: "Project Address is required",
  error_siteowner_required: "Project Owner is required",
  error_siteorg_required: "Project Organization is required",
  error_acc_manager_required: "Account Manager is required",
  error_hubspot_url: "Hubspot Url is required",
  error_homemessage_required: "Home Screen Message is required",
  contact_heading_text: "Contacts",
  add_contact: "Add New Contact",
  edit_contact: "Edit Contact",
  label_name: "Name",
  label_position: "Position",
  label_phone: "Phone Number (Optional)",
  btn_add: "Add",
  btn_remove: "Remove",
  btn_edit: "Edit",
  btn_save: "Save",
  error_name_required: "Name is required",
  error_position_required: "Position is required",
  error_valid_phonenumber: "Please enter valid Phone Number",
  error_required_phone: "Phone is required",
  drag_and_drop: "Drag and drop your files or",
  browse_file: "BROWSE FILES",
  image_uploading: "Uploading Image",
  select: "Select",
  address: "Address",
  hubspot: "HubSpot",
  "pre-live": "PRE-LIVE",
  paused: "PAUSED",
  finished: "Finished",
  invited: "Invited",
  Invited: "Invited",
  restricted: "Restricted",
  blocked: "Blocked",
  logout: "Logout",
  email_optional_label: "Email Address (Optional)",
  project_information: "Project Information",
  project_settings: "Project Settings",
  booking_fields: "Booking Fields",
  project_users: "Project Users",
  project_activity: "Project Activity",
  organization_info: "Organization Info",
  organization_users: "Organization Users",
  organization_activity: "Organization Activity",
  user_information: "User Information",
  associated_organizations: "Associated Organizations",
  associated_projects: "Associated Projects",
  user_activity: "User Activity",
  vehicles_manager: "Vehicles Manager",
  vehicle_type_added: "Vehicle type added",
  vehicle_type_updated: "Vehicle type updated",
  vehicle_type_deleted: "Vehicle type removed",
  no_vehicles_found: "No Vehicles Found",
  add_vehicle: "Add New Vehicle Type",
  multi_vehicle_description:
    "With a multi-chekin booking, you can defined how many vehicles are going to arrive at site, rather than make an individual booking for each.",
  recurring_description:
    "You can schedule a booking to repeat each week or month at a set time and date. For example, weekly garbage collection.",
  resource_only_description:
    "You can book only the available resources without taking booking space at an access point.",
  multi_vehicle: "Multi Vehicle",
  recurring: "Recurring",
  resource_only: "Resource Only",
  Resource: "Resource",
  invite_user: "Invite User",
  add_another: "Add Another",
  role: "Role",
  project_admin: "Project Admin",
  project_manager: "Project Manager",
  project_user: "Project User",
  send_invite: "Send Invite",
  invite_sent: "Successfully Sent Invite",
  security_guard: "Security Guard",
  view_only: "View Only",
  project_senior_user: "Project Senior User",
  profile: "Profile",
  language: "Language",
  flexible_forms: "Flexible Forms",
  add_preset_form: "Add Preset Form",
  create_preset_form: "Create New Form",
  order: "Order",
  form: "Form",
  preset_form: "Preset Form",
  add_question: "Add Question",
  add_section: "Add Section",
  form_title: "Form Title",
  form_title_is_required: "Form Title is required",
  section_title: "Section Title",
  section_title_is_required: "Section Title is required",
  new_question: "NEW QUESTION",
  no_flex_form: "No flexible forms set",
  check_in_form: "Checkin Form",
  check_out_form: "Checkout Form",
  form_updated_successfully: "Form updated successfully",
  form_created_successfully: "Form created successfully",
  label: "Label",
  question_title: "Title",
  form_deleted_successfully: "Successfully deleted form",
  go_to_section_base_on_answer: "Go to section based on answer",
  continue_to: "Continue to",

  // Backend Text
  "Booking Contact": "Booking Contact",
  Comments: "Comments",
  "Vehicle Type": "Vehicle Type",
  phone_number: "Phone Number",
  project_role: "Project Role",
  Van: "Van",
  "18 Wheeler": "18 Wheeler",
  "Box Truck": "Box Truck",
  "Car + Trailer": "Car + Trailer",
  Car: "Car",
  "Concrete Truck": "Concrete Truck",
  "Dump Truck": "Dump Truck",
  "Flatbed Truck": "Flatbed Truck",
  "Garbage Truck": "Garbage Truck",
  "Mobile Crane": "Mobile Crane",
  Other: "Other",
  "Pickup Truck": "Pickup Truck",
  "Pump Truck": "Pump Truck",
  Tanker: "Tanker",
  "Articulated Vehicle": "Articulated Vehicle",
  "Raised Floor Trailer": "Raised Floor Trailer",
  "Low Floor Trailer": "Low Floor Trailer",
  "15t Flat Truck": "15t Flat Truck",
  "15t Unic Truck": "15t Unic Truck",
  "10t Flat Truck": "10t Flat Truck",
  "10t Unic Truck": "10t Unic Truck",
  "8t Flat Truck": "8t Flat Truck",
  "8t Unic Truck": "8t Unic Truck",
  "4t Flat Truck": "4t Flat Truck",
  "4t Unic Truck": "4t Unic Truck",
  "2t Flat Truck": "2t Flat Truck",
  "2t_Flat_Truck": "2t Flat Truck",
  "Concrete Mixer Truck": "Concrete Mixer Truck",
  "16t Rough Terrain-Crane": "16t Rough Terrain-Crane",
  "25t Rough Terrain-Crane": "25t Rough Terrain-Crane",
  "50t Rough Terrain-Crane": "50t Rough Terrain-Crane",
  "65t Rough Terrain-Crane": "65t Rough Terrain-Crane",
  "10t dump truck": "10t Dump Truck",
  "10t Dump Truck": "10t Dump Truck",
  "10t arm roll": "10t Arm Roll",
  "10t Arm Roll": "10t Arm Roll",
  "4t arm roll": "4t Arm Roll",
  "4t Arm Roll": "4t Arm Roll",
};
export default Translation;
