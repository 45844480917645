import login from "./login";
import resetPassword from "./resetPassword";
import forgotPassword from "./forgotPassword";
import siteDetail from "./siteManagment/siteDetail";
import setting from "./siteManagment/setting";
import projectDetails from "./projectDetails";
import booking_field from "./booking_field";
import Translation from "./Translation";

const TRANSLATIONS_EN = {
  login,
  resetPassword,
  forgotPassword,
  siteDetail,
  setting,
  projectDetails,
  booking_field,
  Translation,
};
export default TRANSLATIONS_EN;
