const booking_field = {
  scheduling: "Scheduling",
  field: "Field",
  edit: "Edit",
  duration: "Duration",
  core_fields: "Core Fields",
  active: "Active",
  mandatory: "Mandatory",
  flexible_fields: "Flexible Fields",
  add_new_field: "Add new Field",
  error_something_went_wrong: "Something went wrong",
  core_field_saved_success_fully: "Save Successfully CoreField Detail",
  error_login_again: "Authentication Credentials not provided",
  vehicle_types: "Vehicle Types",
  close: "Close",
  included: "Included",
  cancel: "Cancel",
  save: "Save",
  change_associated_organization: "Change Associated Organization",
  confirm: "Confirm",
  new_organization: "New Organization",
  changed_organization: "Organization Changes SuccessFully",
  no_data_found: "No Data found",
  collapse: "Collapse",
  new_project: "New Project",
  field_removed: "Field Removed",
  this_field_is_active: "This Field is Active",
  this_field_is_mandatory: "This Field is Mandatory",
  core_field_saved: "Save Successfully CoreField Detail",
  search: "Search",
  from: "From",
  to: "To",
  today: "TODAY",
  yesterday: "YESTERDAY",
  start_date_greater_than_end_date:
    "Start Date Should not be greater than End Date",
  back: "Back",
  account_created: "Account Created",
  last_login: "Last Login",
  others: "others",
  showing: "Showing",
  entries_of: "entries of",
  entries: "entries",
  link: "Link",
  status: "Status",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Email_Address: "Email Address",
  Associated_Organization: "Associated Organization",
  Associated_Projects: "Associated Projects",
  account_manager: "Account Manager",
  organization: "Organization",
  reset_filter: "Reset Filter",
  apply_filters: "Apply Filters",
  filters: "Filters",
  next: "Next",
  previous: "Previous",
  no_activity_found: "No Activity Found",
  create_organization: "Add New Organization",
};
export default booking_field;
