import { LanguageAction } from "../Actions/languageAction";
import ActionType from "../Actions/ActionType";
import { getLocalStorage } from "../../Network/ApiService";
import { constants } from "../../Library/Constants";

const intialState = {
  language: getLocalStorage(constants.USER)?.languageCode,
};
const language = (state: object = intialState, action: LanguageAction) => {
  switch (action.type) {
    case ActionType.SET_LANGUAGE:
      return {
        ...state,
        language: action.data,
      };
    default:
      return state;
  }
};
export default language;
