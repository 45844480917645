const login = {
  welcometext: "Welcome To Voyage Control",
  pls_login: "Please Log In",
  email: "Email",
  password: "Password",
  forgot_password: "Forgot Password",
  submit: "Submit",
  login: "Login",
  error_email_required: "Email is required",
  error_valid_email: "Please enter valid email address",
  error_password_required: "Password is required",
  error_something_went_wrong: "Something Went Wrong...",
};
export default login;
