declare module '@mui/material/styles/createPalette' {
  interface SimplePaletteColorOptions {
    lighter: string
    darker: string
    background: string
  }
  interface PaletteColor {
    lighter: string
    darker: string
    background: string
  }
}

// SETUP COLORS

export const GREY = {
  50: '#FFFFFF',
  100: '#EFF3F7',
  200: '#F7F9FC',
  300: '#EFF3F6',
  400: '#DBE2E9',
  500: '#A0AEBD',
  600: '#576473',
  700: '#3F4B57',
  800: '#232D38',
  900: '#141F2B',
}

export const PRIMARY = {
  lighter: '#B8FBF0',
  light: '#1DD4B7',
  main: '#0B907B',
  dark: '#085E50',
  darker: GREY[800],
  contrastText: GREY[50],
  background: '#E9FEFB',
}

export const SECONDARY = {
  lighter: '#8DC4FF',
  light: '#50A3FC',
  main: '#1F6CBF',
  dark: '#004996',
  darker: '#001d60',
  contrastText: GREY[50],
  background: '#E3F1FF',
}

export const INFO = {
  lighter: GREY[200],
  light: GREY[300],
  main: GREY[400],
  dark: GREY[500],
  darker: GREY[600],
  contrastText: GREY[700],
  background: GREY[300],
}

export const SUCCESS = {
  lighter: '#CBFFAE',
  light: '#A6F787',
  main: '#3D8A3D',
  dark: '#056305',
  darker: '#003100',
  contrastText: GREY[50],
  background: '#EAF9DE',
}

export const WARNING = {
  lighter: '#FFEBBF',
  light: '#FFDF9A',
  main: '#EAB242',
  dark: '#9C6800',
  darker: '#7A4F01',
  contrastText: GREY[50],
  background: '#FFF8E9',
}

export const ERROR = {
  lighter: '#FFB5EB',
  light: '#FF75CB',
  main: '#CA2867',
  dark: '#960035',
  darker: GREY[800],
  contrastText: GREY[50],
  background: '#FFECFC',
}

const palette = {
  common: { black: '#000', white: GREY[50] },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: { ...GREY },
}

export default palette
