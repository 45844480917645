import {
  OrganizationType,
  userDetailType,
} from '../../../Components/Core/UserModule/UserDetail';
import ActionType from '../../Actions/ActionType';
import { projectAction } from '../../Actions/ProjectModule/projectAction';

export type Project = {
  id: number;
  ref: string;
  isDeleted: false;
  isActive: true;
  owner: userDetailType;
  slug: string;
  name: string;
  address: string;
  hubspotUrl: string;
  organization: OrganizationType;
  region: number;
  status: 'active' | string;
  manager: userDetailType;
  managerName: string;
  createdAt: string;
  modifiedAt: string;
  prefix: string;
  timezone: string;
  logoImage: string;
  bannerImage: string;
  message: '';
};

export type filterType = {
  status:
    | [
        {
          status: string;
          count: number;
          isChecked: boolean;
        }
      ]
    | [];
  accountManager:
    | [
        {
          ref: string;
          email: string;
          count: number;
          isChecked: boolean;
        }
      ]
    | [];
  organization:
    | [
        {
          id: string;
          name: string;
          count: number;
          isChecked: boolean;
        }
      ]
    | [];
};

const initialState = {
  selected: null,
  filterData: {} as filterType,
  showFilterModal: false,
  showDurationModal: false,
  showAddFlexibleField: false,
  flexibleFieldData: null,
  bookingField: {},
  showAddProjectModal: false,
  showCodeAddress: '',
  timezone: '',
  loading: false,
  search: '',
  activeTab: '/project',
  showVehicleTypeModal: false,
  bookingCoreFields: {},
};

type State = { project: typeof initialState };

export const selectSelectedProject = (state: State) =>
  state.project.selected as Project | null;
export const selectFilterData = (state: State) => state.project.filterData;
export const selectShowAddProjectModal = (state: State) =>
  state.project.showAddProjectModal;

const project = (state: object = initialState, action: projectAction) => {
  switch (action.type) {
    case ActionType.SELECT_PROJECT:
      return {
        ...state,
        selected: action.data,
      };
    case ActionType.SET_PROJECT_FILTER:
      return {
        ...state,
        filterData: action.data,
      };
    case ActionType.SHOW_PROJECT_EDIT_MODAL:
      return {
        ...state,
        showFilterModal: action.data,
      };
    case ActionType.SHOW_EDIT_DURATION_MODAL:
      return {
        ...state,
        showDurationModal: action.data,
      };
    case ActionType.SHOW_PROJECT_ADD_MODAL:
      return {
        ...state,
        showAddProjectModal: action.data,
      };
    case ActionType.SET_BOOKING_FILED:
      return {
        ...state,
        bookingField: action.data,
      };
    case ActionType.SHOW_ADD_FLEXIBLE_MODEL:
      return {
        ...state,
        showAddFlexibleField: action.data.isShow,
        flexibleFieldData: action.data.id,
      };
    case ActionType.SET_SHORT_CODE_ADDRESS:
      return {
        ...state,
        showCodeAddress: action.data,
      };
    case ActionType.SET_TIME_ZONE:
      return {
        ...state,
        timezone: action.data,
      };
    case ActionType.SET_LOADER:
      return {
        ...state,
        loading: action.data,
      };
    case ActionType.SET_SEARCH:
      return {
        ...state,
        search: action.data,
      };
    case ActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.data,
      };
    case ActionType.SHOW_VEHICLE_TYPE_MODEL:
      return {
        ...state,
        showVehicleTypeModal: action.data,
      };
    case ActionType.SET_CORE_FILED:
      return {
        ...state,
        bookingCoreFields: action.data,
      };
    default:
      return state;
  }
};
export default project;
