import React from 'react'
import "./style.css"

const index = () => {
  return (
    <div className="body">

      <div className="main">



        <div className="b"><div></div><div></div><div></div><div></div></div>




      </div>
    </div>
  )
}

export default index