export const constants = {
  LOGIN_TOKEN: "token",
  PROJECT: "project",
  PROJECT_SETTING: "project setting",
  USER: "user",
  USER_DETAILS: "User Details",
  SITE_DATA: "Site Data",
  REGION: "Region",
  HOUR_24: "24 hour",
  HOUR_12: "12 hour",
  TIME_FORMAT: "TIME_FORMAT",
  GOOGLE_MAP_MARKER:
    "https://icon-library.com/images/pin-icon-png/pin-icon-png-23.jpg",
};
