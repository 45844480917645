import { createStore, applyMiddleware, compose } from 'redux';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducers from './Reducers/rootReducer';

const middleWares = [thunk];

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store =
  process.env.NODE_ENV === 'development'
    ? createStore(
        rootReducers,
        composeEnhancers(applyMiddleware(...middleWares))
      )
    : createStore(rootReducers, {});

export default store;
