enum ActionType {
  SET_LANGUAGE = "SET_LANGUAGE",
  SET_USER_DETAILS = "SET_USER_DETAILS",
  SET_BOOKINGS_LOADING = "SET_BOOKINGS_LOADING",
  SET_BOOKING_RESULTS = "SET_BOOKING_RESULTS",
  SET_BOOKING_PAGINATION = "SET_BOOKING_PAGINATION",
  SELECT_ALL_BOOKINGS = "SELECT_ALL_BOOKINGS",
  DESELECT_ALL_BOOKINGS = "DESELECT_ALL_BOOKINGS",
  TOGGLE_BOOKING_SELECTION = "TOGGLE_BOOKING_SELECTION",
  SET_BOOKING_STATUSES = "SET_BOOKING_STATUSES",
  SET_TOTAL_BOOKINGS_AMOUNT = "SET_TOTAL_BOOKINGS_AMOUNT",
  SET_BOOKING_SEARCH = "SET_BOOKING_SEARCH",
  SET_BOOKING_DATES = "SET_BOOKING_DATES",
  RESET_BOOKING_FILTER = "RESET_BOOKING_FILTER",
  RESET_ALL_BOOKING_FILTERS = "RESET_ALL_BOOKING_FILTERS",
  SET_INITIAL_BOOKING_FILTERS = "SET_INITIAL_BOOKING_FILTERS",
  TOGGLE_FILTER = "TOGGLE_FILTER",
  APPLY_BOOKING_FILTERS = "APPLY_BOOKING_FILTERS",
  SELECT_PROJECT = "SELECT_PROJECT",
  SET_PROJECT_FILTER = "SET_PROJECT_FILTER",
  SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS",
  SHOW_PROJECT_EDIT_MODAL = "SHOW_PROJECT_EDIT_MODAL",
  SHOW_EDIT_DURATION_MODAL = "SHOW_EDIT_DURATION_MODAL",
  SET_BOOKING_FILED = "SET_BOOKING_FILED",
  SHOW_ADD_FLEXIBLE_MODEL = "SHOW_ADD_FLEXIBLE_MODEL",
  SHOW_PROJECT_ADD_MODAL ="SHOW_PROJECT_ADD_MODAL",
  SET_SHORT_CODE_ADDRESS= "SET_SHORT_CODE_ADDRESS",
  SET_TIME_ZONE = "SET_TIME_ZONE",
  SET_LOADER = "SET_LOADER",
  SET_SEARCH = "SET_SEARCH",
  SET_ACTIVE_TAB = "SET_ACTIVE_TAB",
  SET_USER_FILTER = "SET_USER_FILTER",
  SET_ORG_FILTER = "SET_ORG_FILTER",
  SHOW_VEHICLE_TYPE_MODEL = "SHOW_VEHICLE_TYPE_MODEL",
  SET_CORE_FILED="SET_CORE_FILED"
}

export default ActionType;
