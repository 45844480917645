import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// component
import AppRoutes from "./AppRoutes";
import Toaster from "./Components/UI/Toaster";
// redux
import { setLanguage } from "./store/Actions/langaugeActionCreator";

// helper
import "./Language/i18n";

// css

import "./Assets/css/bootstrap.min.css";
import "./Assets/css/style.css";
import "./Assets/css/global-admin.css";
import { ThemeProvider, createTheme } from "@mui/material";
import theme from "./theme";

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const local = useSelector((state: any) => state?.language?.language);

  useEffect(() => {
    if (local !== undefined) {
      dispatch(setLanguage(local));
      i18n.changeLanguage(local);
    } else {
      dispatch(setLanguage("en"));
      i18n.changeLanguage("en");
    }
  }, [local]);

  return (
    <ThemeProvider theme={theme}>
      <div className="global-admin">
        <AppRoutes />
        <Toaster />
      </div>
    </ThemeProvider>
  );
}

export default App;
