const projectDetails = {
  booking_type: "Booking Type",
  site_defaults: "Site Defaults",
  project: "Projects",
  total_project: "Total Projects",
  project_status: "Project Status",
  active_project: "Active Projects",
  site_settings: "Site Settings",
  automatic_approval: "Automatic Approval",
  automatic_approval_description:
    "All Booking will automatically approved after they are made.",
  save_details: "Save details",
  create_project: "Create New Project",
  create_organization: "Add New Organization",
  created_project: "Created New Project",
  error_something_went_wrong: "Something Went Wrong",
  unauthorize_user: "Access Denied",
  edit_project_details: "Edit Project Detail",
  credential_not_provided: "Authentication Credentials Not Provided",
  clear_all: "Clear All",
  filters: "Filters",
  status: "Status",
  account_manager: "Account Manager",
  organization: "Organization",
  btn_back: "Back",
  date_created: "Date Created",
  local_site_time: "Local Time at Site",
  successfully_edit_contact: "Successfully Edit Contact",
  invalid_url: "Please enter valid URL",
  project_owner_email_address: "Project Owner Email Address",
  project_edited: "Project Details Edited SuccessFully",
  please_contact_selected_owner_to_accept_the_invitation:
    "Please Contact selected Owner To Accept the Invitation",
  please_select_owner: "Please select Owner",
  success_fully_added_contact: "Successfully Add Contact",
  image_uploaded: "Successfully uploaded Image",
  contact_deleted: "Successfully deleted Contact",
  project_organization: "Project Organization",
  select_site: "Select Site",
  go: "GO",
  actions: "Actions",
  go_to_project: "Go to project",
  project_settings_updated: "Project Setting Updated",
  organization_created: "Organization Created:",
  back: "Back",
  invalid_date: "Invalid Date",
  added_flexible_field_data: "Add Flexible Field Data",
  title_is_required: "Title is required",
  question_is_required: "Question is required",
  option_is_required: "Option is required",
  title: "Title*",
  question: "Question*",
  statement: "Statement*",
  field_type: "Field Type*",
  options: "Options*",
  add: "Add",
  add_new_option: "Add New Option",
  edit_field: "Edit Field",
  add_field: "Add Field",
  free_text: "Free Text",
  cancel: "Cancel",
  link: "Link",
  confirm_text: "Are you sure you want to permanently delete this",
  can_not_undo: "You can’t undo this action",
  delete: "Delete",
  select_role: "Select Role",
  user_removed: "User is removed From Organization",
  organization_updated: "Organization Role updated",
  no_user: "No User found ",
  close: "Close",
  flex_fields_added: "Add Successfully Flexible Field",
  edit_flex_field: "Edit Flexible Field",
  add_flex_field: "Add New Flexible Field",
  org_name: "Organization Name",
  org_name_required: "Organization Name is Required",
  address_line_1: "Address Line 1",
  address_line_1_required: "Address Line 1 is Required",
  address_line_2: "Address Line 2",
  address_line_2_required: "Address Line 2 is Required",
  town_city: "Town/City",
  region_state: "Region/State",
  town_city_required: "Town/City is Required",
  region_state_required: "Region/State is Required",
  allowedDomains: "Verified Email Domain",
  allowedDomains_required: "Verified Email Domain is Required",
  zip_code: "Zipcode",
  zip_code_required: "Zipcode is Required",
  country: "Country",
  country_required: "Country is Required",
  confirm: "Confirm",
};
export default projectDetails;
